import React from "react";

import { FaEnvelope, FaFacebookSquare, FaInstagram, FaLinkedin, FaPhoneAlt, FaTwitterSquare } from "react-icons/fa";

import buildings from "../../Assets/images/buildings.webp";

import "./Footer.scss";

const FooterComponent = () => {
    return (
        <>
            <img className="Footer-Top" src={ buildings } alt="" width="0" height="0" />
            <div className="Footer">
                <div className="Footer-Container">
                    <div className="Footer-Intro">
                        VOJ Webtech offers a complete online solution to entrepreneurs, SMEs artists, and innovators.
                    </div>
                    <div className="Footer-Social">
                        <a rel="noreferrer" target="_blank" href="https://www.facebook.com/vojwebtechbrandboosters/"><FaFacebookSquare /></a>
                        <a rel="noreferrer" target="_blank" href="https://www.instagram.com/vojwebtech_brandboosters/"><FaInstagram /></a>
                        <a rel="noreferrer" target="_blank" href="tel:+91 86850 00525"><FaLinkedin /></a>
                        <a rel="noreferrer" target="_blank" href="tel:+91 86850 00525"><FaTwitterSquare /></a>
                    </div>
                    <a rel="noreferrer" target="_blank" href="tel:+91 86850 00525" className="Footer-Phone">
                        <FaPhoneAlt />
                        +91 86850 00525
                    </a>
                    <a href="mailto:info@vojwebtech.com" target="_blank" rel="noreferrer" className="Footer-Email">
                        <FaEnvelope />
                        info@vojwebtech.com
                    </a>
                    <div className="Footer-Links">
                        <a href="#">Privacy</a> | <a href="#">Terms</a>
                    </div>
                    <div className="Footer-Copyrights">
                        Copyright © 2025 All Rights Reserved. Developed And Maintained by VOJ WebTech.
                    </div>
                </div>
            </div>
        </>
    );
};

export default FooterComponent;