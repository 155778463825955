import React from "react";
import logo from "../../Assets/images/logo.webp";
import { FaEnvelope, FaFacebookSquare, FaInstagram, FaLinkedin, FaPhoneAlt, FaTwitterSquare, FaWhatsapp } from "react-icons/fa";
import { AiOutlineMenu } from "react-icons/ai";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import "./Header.scss";

const HeaderComponent = ({ onMenuIconClick, menuOpen }) => {
    const renderTopbar = () => (
        <div className="Header-Topbar">
            <div className="Header-Topbar-Wrapper">
                <div className="Header-Topbar-Left">
                    <a href="tel:+91 86850 00525" target="_blank" rel="noreferrer">
                        <FaPhoneAlt />
                        +91 86850 00525
                    </a>
                    <a href="mailto:info@vojwebtech.com" target="_blank" rel="noreferrer">
                        <FaEnvelope />
                        info@vojwebtech.com
                    </a>
                </div>
                <div />
                <div className="Header-Topbar-Right">
                    <a rel="noreferrer" target="_blank" href="https://www.facebook.com/vojwebtechbrandboosters/"><FaFacebookSquare /></a>
                    <a rel="noreferrer" target="_blank" href="https://www.instagram.com/vojwebtech_brandboosters/"><FaInstagram /></a>
                    <a rel="noreferrer" target="_blank" href="tel:+91 86850 00525"><FaLinkedin /></a>
                    <a rel="noreferrer" target="_blank" href="tel:+91 86850 00525"><FaTwitterSquare /></a>
                </div>
            </div>
        </div>
    );

    const renderMainContent = () => (
        <div className="Header-Main-Wrapper">
            <div className="Header-Logo">
                <Link to="/">
                    <img src={ logo } alt="Voj WebTech" width="0" height="0" />
                </Link>
            </div>
            <div className="Header-NavArea">
                <span className="Header-MobileMenu" onClick={ onMenuIconClick }><AiOutlineMenu /></span>
                <ul className={`Header-Navigation ${menuOpen ? "Header-Navigation-Open" : ""}`}>
                    <li className="Header-Navigation-Item"><Link onClick={ onMenuIconClick } className="Header-Navigation-Link" to="/">Home</Link></li>
                    <li className="Header-Navigation-Item"><Link onClick={ onMenuIconClick } className="Header-Navigation-Link" to="/about-us">About</Link></li>
                    <li className="Header-Navigation-Item">
                        <span className="Header-Navigation-Link">Services</span>
                        <ul className="Header-Navigation-Item-DropDown">
                            <li><Link onClick={ onMenuIconClick } to="/website-designing">Website Designing</Link></li>
                            <li><Link onClick={ onMenuIconClick } to="/website-development">Website Development</Link></li>
                            <li><Link onClick={ onMenuIconClick } to="/logo-designing">Logo Designing</Link></li>
                            <li><Link onClick={ onMenuIconClick } to="/digital-marketing">Digital Marketing</Link></li>
                            <li><Link onClick={ onMenuIconClick } to="/mobile-application-development">Mobile Application Development</Link></li>
                        </ul>
                    </li>
                    <li className="Header-Navigation-Item"><Link onClick={ onMenuIconClick } className="Header-Navigation-Link" to="/contact-us">Contact</Link></li>
                    <li className="Header-Navigation-Item">
                        <a onClick={ onMenuIconClick } href="https://api.whatsapp.com/send?phone=918685000525&text=Hi!" rel="noreferrer" target="_blank" className="Header-Navigation-Link" to="/contact-us"><FaWhatsapp /> Free Estimation</a>
                    </li>
                </ul>
            </div>
        </div>
    );

    return (
        <div className="Header">
            { renderTopbar() }
            <div className="Header-Main">
                { renderMainContent() }
            </div>
        </div>
    );
};

HeaderComponent.propTypes = {
    onMenuIconClick: PropTypes.func.isRequired,
    menuOpen: PropTypes.bool.isRequired
};

export default HeaderComponent;